import React from 'react'; 
import { graphql, useStaticQuery } from 'gatsby'; 
import Layout from '../../components/Layout'; 
import styled from 'styled-components'; 
import Img from 'gatsby-image'; 
import Link from '../../components/Link'; 
 
import { getImages } from '../../utils/common'; 
import { calcvw, mediaQueries } from '../../utils/mediaQueries'; 
 
const Image = styled.img` 
  max-width: 100%; 
`; 
 
const Image1 = styled(Img)` 
  max-width: 100%; 
`; 
 
const Button = styled(Link)` 
  position: absolute; 
  top: 20%; 
  left: 20%; 
  width: 20%; 
  height: 20%; 
  background-color: red; 
  z-index: 100; 
 
  ${mediaQueries('md')} { 
    width: ${calcvw(100, 767)}; 
  } 
`; 
 
const useImageData = () => { 
  const { allFile } = useStaticQuery(graphql` 
    { 
      allFile(filter: { relativeDirectory: { eq: "images/redeem" } }) { 
        nodes { 
          name 
          childImageSharp { 
            fluid(
              maxWidth: 1200,
              quality: 100,
              sizes: "(max-width: 768px) 100vw, 50vw"
            ) { 
              ...GatsbyImageSharpFluid 
            } 
          } 
        } 
      } 
    } 
  `); 
 
  return allFile.nodes; 
}; 
 
const Redeem = () => { 
  const images = getImages(useImageData()); 
 
  return ( 
    <Layout> 
      <div className="position-relative"> 
        <Image1 fluid={images['Bio-Redemption-Menu-03242025']} />
      </div> 
    </Layout> 
  ); 
}; 
 
export default Redeem; 
